@use "styles" as *;

div.slide {
  height: auto;
}

div.swiper {
  overflow: initial;
}

div.initialSlide {
  --space-between: 20px;
  --number-slides: 2;
  margin-inline-end: var(--space-between);
  width: calc((100% - (var(--space-between) * (var(--number-slides) - 1))) / var(--number-slides));

  @include tablet {
    --space-between: 12px;
    --number-slides: 4;
  }
  @include sm-desktop {
    --space-between: 80px;
    --number-slides: 4;
  }
  @include lg-desktop {
    --space-between: 166px;
    --number-slides: 5;
  }
}
